import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";

import App from "./App";
import { SamzapThemeProvider } from "./context/Theme/ThemeContext";

ReactDOM.render(
  <CssBaseline>
    <SamzapThemeProvider>
      <App />
    </SamzapThemeProvider>
  </CssBaseline>,
  document.getElementById("root")
);