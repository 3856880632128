import React, { useState, useContext } from "react";

import {
  Button,
  CssBaseline,
  TextField,
  //Typography,
  InputAdornment,
  IconButton,
  //Link
} from '@material-ui/core';

import {
  Visibility,
  VisibilityOff,
  AccountCircleOutlined,
  LockOutlined,
} from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";

//import { defaultBgColor } from '../../contant';
import { versionSystem } from "../../../package.json";
//import { nomeEmpresa } from "../../../package.json";
//import { useThemeMode } from "../../context/Theme/ThemeContext";
import bg from '../../assets/5124557.svg';

/*const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      © {new Date().getFullYear()}
      {" - "}
      <Link color="inherit" href="#">
        {nomeEmpresa} - v {versionSystem}
      </Link>
      {"."}
    </Typography>
  );
};*/

const useStyles = makeStyles(theme => ({
  paper: {
    "height": "100vh",
    "margin": "0 auto",
    "padding": "0 50px",
    "max-width": "450px",
    "display": "flex",
    "min-width": "350px",
    "min-height": "100%",
    "align-items": "center",
    "flex-direction": "column",
    "justify-content": "center",

  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "150px",
    //backgroundColor: defaultBgColor,
    //backgroundColor: theme.palette.background.primary,
  },
  left_bg: {
    left: 0,
    width: "45%",
    height: "100vh",
    position: "fixed",
  },
  right_bg: {
    "background-image": `url(${bg})`,
    "background-repeat": "no-repeat",
    "background-size": "cover",
    "background-position": "center",
    position: "fixed",
    right: 0,
    width: "55%",
    height: "100vh",
  },
  footer: {
    position: "fixed",
    bottom: 20,
    display: "flex",
  },
  logo_name: {
    "font-size": "35px",
    //"-webkit-text-stroke": "1px",
  },
  brand_name: {
    //"color": defaultBgColor,
    color: theme.palette.primary.main,
    "font-weight": "700",
    "font-size": "45px",
    "-webkit-text-stroke": "1px",
  },
  '@media (max-width: 900px)': {
    left_bg: {
      width: "100%"
    },
    right_bg: {
      width: "0%"
    },
  },
}));

const Login = () => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  //const { darkMode } = useThemeMode();

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = e => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = e => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <div>
      <CssBaseline />
      <div className={classes.left_bg}>
        <div className={classes.paper}>
          <div className={classes.logo_name}>Samsoft <span className={classes.brand_name}>SamZap</span></div>
          <div>{versionSystem}</div>
          <form className={classes.form} noValidate onSubmit={handlSubmit}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleOutlined />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="email"
              label={i18n.t("login.form.email")}
              name="email"
              value={user.email}
              onChange={handleChangeInput}
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="password"
              label={i18n.t("login.form.password")}
              id="password"
              value={user.password}
              onChange={handleChangeInput}
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((e) => !e)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {i18n.t("login.buttons.submit")}
            </Button>

          </form>
        </div>
      </div>
      <div className={classes.right_bg}></div>
      {/*<Box mt={8} className={classes.footer}><Copyright /></Box>*/}
    </div>
  );
};

export default Login;
