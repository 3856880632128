import React, { useEffect, useState } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import CssBaseline from "@material-ui/core/CssBaseline";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useThemeMode } from "./context/Theme/ThemeContext";
import lightBackground from "./assets/wa-background-light.png";
import darkBackground from "./assets/wa-background-dark.png";
import { system } from "./config.json";
import { ptBR } from "@material-ui/core/locale";

const App = () => {
  const { darkMode } = useThemeMode();
  let theme = React.useMemo(() => {
    return darkMode ? "dark" : "light";
  }, [darkMode])

  const [locale, setLocale] = useState();

  const lightTheme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8",
        },
      },
      palette: {
        primary: { main: system.color.lightTheme.palette.primary || "#6B62FE" },
        secondary: { main: system.color.lightTheme.palette.secondary || "#F50057" },
        toolbar: { main: system.color.lightTheme.toolbar.background || "#6B62FE" },
        menuItens: { main: system.color.lightTheme.menuItens || "#ffffff" },
        sub: { main: system.color.lightTheme.sub || "#ffffff" },
        toolbarIcon: { main: system.color.lightTheme.toolbarIcon || "#ffffff" },
        divide: { main: system.color.lightTheme.divide || "#E0E0E0" },
        messageItem: {
          background: system.color.lightTheme.messageItem.background || "#dcf8c6",
          color: system.color.lightTheme.messageItem.color || "#000000",
        },
        messageItemMe: {
          background: system.color.lightTheme.messageItemMe.background || "#ffffff",
          color: system.color.lightTheme.messageItemMe.color || "#000000",
        },
        text: {
          closed: {
            primary: system.color.lightTheme.palette.text.closed.primary || "#000000",
            secondary: system.color.lightTheme.palette.text.closed.secondary || "#000000",
          }
        },
      },
      backgroundImage: `url(${lightBackground})`,
    },
    locale
  );

  const darkTheme = createTheme(
    {
      overrides: {
        MuiCssBaseline: {
          '@global': {
            body: {
              backgroundColor: "#080d14",
            }
          }
        }
      },
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#ffffff",
        },
      },
      palette: {
        primary: { main: system.color.darkTheme.palette.primary || "#52d869" },
        secondary: { main: system.color.darkTheme.palette.secondary || "#ff9100" },
        toolbar: { main: system.color.darkTheme.toolbar.background || "#52d869" },
        menuItens: { main: system.color.darkTheme.menuItens || "#181d22" },
        sub: { main: system.color.darkTheme.sub || "#181d22" },
        toolbarIcon: { main: system.color.darkTheme.toolbarIcon || "#181d22" },
        divide: { main: system.color.darkTheme.divide || "#080d14" },
        background: {
          default: system.color.darkTheme.palette.background.default || "#080d14",
          paper: system.color.darkTheme.palette.background.paper || "#181d22",
        },
        messageItem: {
          background: system.color.darkTheme.messageItem.background || "#1a4a3f",
          color: system.color.darkTheme.messageItem.color || "#52d869",
        },
        messageItemMe: {
          background: system.color.darkTheme.messageItemMe.background || "#202c33",
          color: system.color.darkTheme.messageItemMe.color || "#ffffff",
        },
        text: {
          primary: system.color.darkTheme.palette.text.primary || "#52d869",
          secondary: system.color.darkTheme.palette.text.secondary || "#ffffff",
          closed: {
            primary: system.color.darkTheme.palette.text.closed.primary || "#255e2f",
            secondary: system.color.darkTheme.palette.text.closed.secondary || "#ffffff",
          }
        },
      },
      backgroundImage: `url(${darkBackground})`,
    },
    locale
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <Routes />
      <CssBaseline />
    </ThemeProvider>
  );
};

export default App;
