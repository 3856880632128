import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import IconButton from '@material-ui/core/IconButton';
import { i18n } from "../../translate/i18n";
import DoneIcon from '@material-ui/icons/Done';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReplayIcon from '@material-ui/icons/Replay';
import api from "../../services/api";
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
//import useWhatsApps from "../../hooks/useWhatsApps";
import AcceptTicketWithouSelectQueue from "../AcceptTicketWithoutQueueModal";
import { useThemeMode } from "../../context/Theme/ThemeContext";
import { getUUIDTicket } from "../../helpers";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
  },

  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },

  lastMessageTime: {
    justifySelf: "flex-end",
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: 20,
  },

  newMessagesCount: {
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
  },

  bottomButton: {
    top: "12px",
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
  },

  acceptButton: {
    position: "absolute",
    left: "50%",
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  userTag: {
    position: "absolute",
    marginRight: 5,
    right: 20,
    bottom: 30,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: "1px solid #CCC",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.9em",
  },

  ticketClosed: {
    background: "#d1d1d1",
    "&:hover": {
      background: "#F1F1F1",
    },
  },

  backgroundClosed: {
    background: "#2b3430",
    "&:hover": {
      background: "#2b3430",
    },
  },

  textClosedPrimary: {
    color: theme.palette.text.closed.primary
  },

  textClosedSecondary: {
    color: theme.palette.text.closed.secondary
  },

  ticketSelected: {
    background: "#d2d9ee",
    "&:hover": {
      background: "#F1F1F1",
    },
  }
}));

const TicketListItem = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  //const { whatsApps } = useWhatsApps();
  const [acceptTicketWithouSelectQueueOpen, setAcceptTicketWithouSelectQueueOpen] = useState(false);
  const { darkMode } = useThemeMode();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleAcceptTicket = async id => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${getUUIDTicket(ticket)}`);
  };

  /*const queueName = selectedTicket => {
    let name = null;
    let color = null;
    user.queues.forEach(userQueue => {
      if (userQueue.id === selectedTicket.queueId) {
        name = userQueue.name;
        color = userQueue.color;
      }
    });
    return {
      name,
      color
    };
  }*/

  const handleOpenAcceptTicketWithouSelectQueue = () => {
    setAcceptTicketWithouSelectQueueOpen(true);
  };

  const handleReopenTicket = async (id, contactId) => {
    setLoading(true);
    try {
      const { data: newTicket } = await api.post("/tickets", {
				contactId: contactId,
        status: "open",
				userId: user?.id,
			});
			history.push(`/tickets/${getUUIDTicket(newTicket)}`);
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    //history.push(`/tickets/${getUUIDTicket(ticket)}`);
  };

  const handleReturnTicket = async id => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "pending",
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${getUUIDTicket(ticket)}`);
  };

  const handleClosedTicket = async id => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${getUUIDTicket(ticket)}`);
  };

  const handleSpyTicket = async id => {
    //setLoading(true);
		/*try {
			await api.put(`/tickets/${id}`, {
				status: "pending",
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}*/
		history.push(`/tickets/${getUUIDTicket(ticket)}?spy=S`);
  };


  const handleSelectTicket = ticket => {
    history.push(`/tickets/${getUUIDTicket(ticket)}`);
  };

  return (
    <React.Fragment key={ticket.id}>
      <AcceptTicketWithouSelectQueue
        modalOpen={acceptTicketWithouSelectQueueOpen}
        onClose={(e) => setAcceptTicketWithouSelectQueueOpen(false)}
        ticket={ticket}
      />
      <ListItem
        dense
        button
        onClick={(e) => {
          if (ticket.status === "pending") return;
          handleSelectTicket(ticket);
        }}
        selected={ticketId && ticketId === getUUIDTicket(ticket)}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        }) && (ticket.status === "closed" ? classes.ticketClosed && (darkMode && classes.backgroundClosed) : "")}
      >
        <Tooltip
          arrow
          placement="right"
          title={ticket.queue?.name || "Sem departamento"}
        >
          <span
            style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
            className={classes.ticketQueueColor}
          ></span>
        </Tooltip>
        <ListItemAvatar>
          <Avatar src={ticket?.contact?.profilePicUrl} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <span className={classes.contactNameWrapper}>
              <Typography
                noWrap
                component="span"
                variant="body2"
                color={ticket.status === "closed" ? "inherit" : "textPrimary"}
                className={ticket.status === "closed" ? classes.textClosedPrimary : ""}
              >
                {ticket.contact.name}
              </Typography>
              {/*ticket.status === "closed" && (
                <Badge overlap="rectangular"
                  className={classes.closedBadge}
                  badgeContent={i18n.t("tickets.tabs.closed.title2")}
                  color="primary"
                />
              )*/}
              {ticket.lastMessage && (
                <Typography
                  className={classes.lastMessageTime && (ticket.status === "closed" ? classes.textClosedSecondary : "")}
                  component="span"
                  variant="body2"
                >
                  {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                    <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                  ) : (
                    <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                  )}
                </Typography>
              )}
              {/*{ticket.whatsappId && whatsApps.length > 1 && (
                <div
                  className={classes.userTag && (ticket.status === "closed" ? classes.textClosedSecondary : "")}
                  title={i18n.t("ticketsList.connectionTitle")}
                >
                  {ticket.whatsapp?.name}
                </div>
              )}

              {ticket.channel !== "whatsapp" && whatsApps.length > 1 && (
                <div
                  className={classes.userTag && (ticket.status === "closed" ? classes.textClosedSecondary : "")}
                  title={i18n.t("ticketsList.connectionTitle")}
                >
                  {ticket.channel}
                </div>
              )}*/}
            </span>
          }
          secondary={
            <span className={classes.contactNameWrapper}>
              <Typography
                className={classes.contactLastMessage && (ticket.status === "closed" ? classes.textClosedSecondary : "")}
                noWrap
                component="span"
                variant="body2"
              >
                {ticket.lastMessage ? (
                  <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                ) : (
                  <br />
                )}
              </Typography>

              <Badge overlap="rectangular"
                className={classes.newMessagesCount}
                badgeContent={ticket.unreadMessages}
                classes={{
                  badge: classes.badgeStyle,
                }}
              />
            </span>
          }
        />
        {(ticket.status === "pending" && (ticket.queue === null || ticket.queue === undefined)) && (
          <Tooltip title={i18n.t("ticketsList.items.accept")}>
            <IconButton
              className={classes.bottomButton}
              color="primary"
              onClick={e => handleOpenAcceptTicketWithouSelectQueue()}
              loading={loading}>
              <DoneIcon />
            </IconButton>
          </Tooltip>
        )}
        {ticket.status === "pending" && ticket.queue !== null && (
          <Tooltip title={i18n.t("ticketsList.items.accept")}>
            <IconButton
              className={classes.bottomButton}
              color="primary"
              onClick={e => handleAcceptTicket(ticket.id)} >
              <DoneIcon />
            </IconButton>
          </Tooltip>
        )}
        {(ticket.status === "pending" || (ticket.status === "open" && (!ticketId || ticketId !== getUUIDTicket(ticket)))) && (
          <Tooltip title={i18n.t("ticketsList.items.spy")}>
            <IconButton
              className={classes.bottomButton}
              color="primary"
              onClick={e => handleSpyTicket(ticket.id)} >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        )}
        {ticket.status === "pending" && (
          <Tooltip title={i18n.t("ticketsList.items.close")}>
            <IconButton
              className={classes.bottomButton}
              color="primary"
              onClick={e => handleClosedTicket(ticket.id)} >
              <ClearOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
        {ticket.status === "open" && (
          <Tooltip title={i18n.t("ticketsList.items.return")}>
            <IconButton
              className={classes.bottomButton}
              color="primary"
              onClick={e => handleReturnTicket(ticket.id)}>
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        )}
        {ticket.status === "open" && (
          <Tooltip title={i18n.t("ticketsList.items.close")}>
            <IconButton
              className={classes.bottomButton}
              color="primary"
              onClick={e => handleClosedTicket(ticket.id)} >
              <ClearOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
        {ticket.status === "closed" && (
          <IconButton
            className={classes.bottomButton}
            color="primary"
            onClick={e => handleReopenTicket(ticket.id, ticket.contactId)} >
            <ReplayIcon />
          </IconButton>
        )}
        {ticket.status === "closed" && (
          <IconButton
            className={classes.bottomButton}
            color="primary" >
          </IconButton>
        )}
      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};

export default TicketListItem;
