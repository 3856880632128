import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import UndoRoundedIcon from "@material-ui/icons/UndoRounded";
import Tooltip from "@material-ui/core/Tooltip";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    marginRight: 6,
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const TicketActionButtonsCustom = ({ ticket, setTab, setState }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const ticketOptionsMenuOpen = Boolean(anchorEl);
  const { user } = useContext(AuthContext);
  const [setAcceptTicketWithouSelectQueueOpen] = useState(false);
  const { setCurrentTicket } = useContext(TicketsContext);

  const customTheme = createTheme({
    palette: {
      primary: green,
    },
  });

  const handleOpenTicketOptionsMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseTicketOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  /*const handleReopenTicket = async (e, userId) => {
    if (!ticket.contactId) return;

    setLoading(true);

    try {
      const { data: newTicket } = await api.post("/tickets", {
        contactId: ticket.contactId,

        userId: userId,

        status: "open",
      });

      history.push(`/tickets/${getUUIDTicket(newTicket)}`);
    } catch (err) {
      setLoading(false);

      toastError(err);
    }
  };*/

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
        useIntegration: status === "closed" ? false : ticket.useIntegration,
        promptId: status === "closed" ? false : ticket.promptId,
        integrationId: status === "closed" ? false : ticket.integrationId,
      });

      setLoading(false);
      if (status === "open") {
        setTab("open");
        //console.log("status === open")
        //console.log(ticket)
        //setCurrentTicket({ id: ticket.id, ...ticket, code: "#open" });
        //console.log(`history.push('/tickets/${ticket.uuid}')`);
        history.push(`/tickets`);
        
        const delay = setTimeout(() => {
          history.push(`/tickets/${ticket.uuid}`);
        }, 500);

        return () => clearTimeout(delay);

        //window.location.reload();

        //this.forceUpdate();

        /*return () => {
          setState();
        };*/
      } else {
        setTab(status);
        //console.log("status !== open")
        setCurrentTicket({ id: null, code: null });
        history.push("/tickets");
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleOpenAcceptTicketWithouSelectQueue = () => {
    setAcceptTicketWithouSelectQueueOpen(true);
  };

  return (
    <div className={classes.actionButtons}>
      {ticket.status === "closed" && (
        <ButtonWithSpinner
          loading={loading}
          startIcon={<Replay color="primary" />}
          size="small"
          onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
        >
          {i18n.t("messagesList.header.buttons.reopen")}
        </ButtonWithSpinner>
      )}
      {ticket.status === "open" && (
        <>
          <Tooltip title={i18n.t("messagesList.header.buttons.return")}>
            <IconButton
              onClick={(e) => handleUpdateTicketStatus(e, "pending", null)}
              color="primary"
            >
              <UndoRoundedIcon />
            </IconButton>
          </Tooltip>
          <ThemeProvider theme={customTheme}>
            <Tooltip title={i18n.t("messagesList.header.buttons.resolve")}>
              <IconButton
                onClick={(e) => handleUpdateTicketStatus(e, "closed", user?.id)}
                color="primary"
              >
                <CheckCircleIcon />
              </IconButton>
            </Tooltip>
          </ThemeProvider>
          {/* <ButtonWithSpinner
						loading={loading}
						startIcon={<Replay color="primary" />}
						size="small"
						onClick={e => handleUpdateTicketStatus(e, "pending", null)}
					>
						{i18n.t("messagesList.header.buttons.return")}
					</ButtonWithSpinner>
					<ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="primary"
						onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
					>
						{i18n.t("messagesList.header.buttons.resolve")}
					</ButtonWithSpinner> */}
          <IconButton onClick={handleOpenTicketOptionsMenu}>
            <MoreVert color="primary" />
          </IconButton>
          <TicketOptionsMenu
            ticket={ticket}
            anchorEl={anchorEl}
            menuOpen={ticketOptionsMenuOpen}
            handleClose={handleCloseTicketOptionsMenu}
          />
        </>
      )}
      {ticket.status === "pending" &&
        (ticket.queue === null || ticket.queue === undefined) && (
          <ButtonWithSpinner
            loading={loading}
            size="small"
            variant="contained"
            color="primary"
            onClick={(e) => handleOpenAcceptTicketWithouSelectQueue()}
          >
            {i18n.t("messagesList.header.buttons.accept")}
          </ButtonWithSpinner>
        )}

      {ticket.status === "pending" && ticket.queue !== null && (
        <ButtonWithSpinner
          loading={loading}
          size="small"
          variant="contained"
          color="primary"
          onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
        >
          {i18n.t("messagesList.header.buttons.accept")}
        </ButtonWithSpinner>
      )}
    </div>
  );
};

export default TicketActionButtonsCustom;
