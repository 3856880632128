import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import Ticket from "../../components/Ticket/";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import Hidden from "@material-ui/core/Hidden";
import toastError from "../../errors/toastError";
import { Avatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    // // backgroundColor: "#eee",
    // padding: theme.spacing(4),
    height: `calc(100% - 48px)`,
    overflowY: "hidden",
  },
  chatTitle: {
    width: "100%",
    textAlign: "center",
    padding: "10px",
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarTitle: {
    marginRight: "10px",
  },
  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const ChatExport = () => {
  const history = useHistory();
  const classes = useStyles();
  const { ticketId } = useParams();
  const [ticket, setTicket] = useState({});

  /*const loaded = async () => {
    window.print();
  };*/

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/" + ticketId);

          setTicket(data);

          //console.log(data?.contact?.profilePicUrl)

          //if (data?.contact?.profilePicUrl == undefined) {
            setTimeout(() => {
              window.print();
            }, 500);
          //}
        } catch (err) {
          toastError(err);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId, history]);

  return (
    <div>
      <div className={classes.chatPapper}>
        <Grid container spacing={0}>
          {ticket?.id && (
            <Paper className={classes.chatTitle}>
              <span className={classes.titleContainer}>
                <Avatar
                  src={ticket?.contact?.profilePicUrl}
                  className={classes.avatarTitle}
                  //onLoad={() => loaded()}
                />
                {ticket?.contact?.name} #{ticketId}
              </span>
            </Paper>
          )}
          <Grid item xs={12} md={12} className={classes.messagessWrapper}>
            {/* <Grid item xs={8} className={classes.messagessWrapper}> */}
            {ticketId ? (
              <>
                <Ticket isExport="1" />
              </>
            ) : (
              <Hidden only={["sm", "xs"]}>
                <Paper className={classes.welcomeMsg}>
                  {/* <Paper square variant="outlined" className={classes.welcomeMsg}> */}
                  <span>{i18n.t("chat.noTicketMessage")}</span>
                </Paper>
              </Hidden>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ChatExport;
